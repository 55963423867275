<template>
  <div>
    <p>This input will be hidden unless all of the given values match. If any value is not set or does not match the given value, then this input will not be visible.</p>
    <visibility-rule
      v-for="({ field, op, value }, index) in visibilityRules"
      :key="'vis-' + index"
      :field="field"
      :op="op"
      :value="value"
      :fieldOptions="visibilityOptions"
      @change="(val) => updateVisibilityRule(val, index)"
      @remove="removeVisibilityRule(index)"
    ></visibility-rule>
    <v-btn color="success" @click="visibilityRules.push({ field: '', op: '', value: '' })">
      <v-icon left>fas fa-plus-circle</v-icon>
      Add Visibility Rule
    </v-btn>
  </div>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  props: {
    input: {
      type: Object,
      required: true
    },
    inputList: Array,
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  components: {
    visibilityRule: () => import('./visibility-rule')
  },
  setup (props, { emit }) {
    const visibilityRules = ref(props.input.ifs || [])
    watch(visibilityRules, (ifs) => {
      if (ifs != null && (props.input.ifs == null || JSON.parse(JSON.stringify(ifs)) !== JSON.parse(JSON.stringify(props.input.ifs)))) {
        emit('change', { ifs })
      }
    })
    watch(() => props.input, () => {
      if ('ifs' in props.input && Array.isArray(props.input.ifs)) {
        visibilityRules.value = props.input.ifs
      } else {
        visibilityRules.value = []
      }
    })

    function updateVisibilityRule (val, index) {
      if (val == null) return
      let temp = Object.assign({}, visibilityRules.value[index], val)
      visibilityRules.value.splice(index, 1, temp)
      // emit('change', { ifs: visibilityRules.value })
    }

    function removeVisibilityRule (index) {
      visibilityRules.value.splice(index, 1)
      // emit('change', { ifs: visibilityRules.value })
    }

    const visibilityOptions = computed(() => {
      let arr = []
      props.inputList.forEach((row) => {
        if (row.text === props.input.label) return
        arr.push(row)
      })
      return arr
    })

    return {
      visibilityRules,
      updateVisibilityRule,
      removeVisibilityRule,
      visibilityOptions
    }
  }
}
</script>
